import React, { useState } from 'react';
import { Button } from '../ButtonElements';

import Video from '../../videos/movingcodebackground.mp4';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
} from './HeroElements';

function HeroSection() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <HeroH1>P.o.D is Programmers-on-Demand Platform by ScottDevOps</HeroH1>
        <HeroP>
          Our Programmers-on-Demand platform is for you! 
          Sign up for a new account today and receive $200 in credit towards
          your next on-demand help session with one or more of our 
          Professional Programmers, Troubleshooters, Code Auditors, 
          Performance Analyzers, Debuggers, Code Security Analysts, etc.
        </HeroP>
        <HeroBtnWrapper>
          <Button
            to='signup'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            primary='true'
            dark='true'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Learn More {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
