export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Premium Help',
  headline: 'Unlimited Help and Advice for a Fair Cost',
  description:
    'When you are in a pinch to save time, meet deadlines, or need help with unforseen technical problems, give us a chance to help you. Get access to our exclusive app that allows you to send help requests about your critical programming and technical issues, and your coding questions directly to your assigned Programmer-on-Demand or a custom assembled P.o.D Developer team - just for your specific needs. ',
  buttonLabel: 'Learn More',
  imgStart: false,
  img: require('../../images/svg-1.svg'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Unlimited Access',
  headline: 'Login to your account at any time',
  description:
    'We have you covered no matter where you are located. All you need is an internet connection and a phone or computer. We can do various screen sharing sessions to review code and critical problems, and also review your code repositories and more. We give you a free 30 minute consultation to discuss your critical issue(s) and/or questions.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/svg-2.svg'),
  alt: 'Programmers',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Acquire our Help',
  headline: 'Creating an account is extremely easy',
  description:
    "Get everything set up on your new account in about 5 minutes. All you need to do is add your information, read and agree to the Terms of Service, add credit card information, and we are ready to begin working with you on your problems, issues, questions, etc. Please NOTE: we give away 30 minute free evalution to most situations. Contact us to find out more!",
  buttonLabel: 'Start Now',
  imgStart: false,
  img: require('../../images/svg-3.svg'),
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true
};
