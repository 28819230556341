import React from 'react';
import ReactDOM from 'react-dom';
//import { BrowserRouter } from "react-router-dom";
import App from './App';

const isLocal = (process.env.NODE_ENV === 'development');
const isProd = (process.env.NODE_ENV === 'production');
console.log("isLocal: ", isLocal);
console.log("isProd: ", isProd);
//     <BrowserRouter basename={isLocal ? '/' : '/react/ScottsVirtBank'}>
//    </BrowserRouter>

ReactDOM.render(
  <React.StrictMode>
       <App />
  </React.StrictMode>,
  document.getElementById('root')
);
