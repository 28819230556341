import React from 'react';
import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages';
import SigninPage from './pages/signin';

function App() {
  if (process.env.NODE_ENV === "development")
  console.log('We are running in development');

  return (
    <Router>
      <Switch>
        <Route path='/' component={Home}  />
        <Route path='/signin' component={SigninPage}  />
      </Switch>
    </Router>
  );
}

export default App;
